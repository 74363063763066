<template>
  <div class="bg-charcoal">
    <Text size="xlarge" marginLeft="xxlarge" attention weight="medium" marginTop="xxlarge">Field Alignment Dashboard</Text>
    <div class="p-6">
      <div class="flex justify-between items-center flex-wrap">
        <!-- Layout Toggle -->
        <SelectButton v-model="layout" :options="layoutOptions" :allowEmpty="false" class="ml-11">
          <template #option="{ option }">
            <Icon v-if="option === 'list'" icon="heroicons:bars-3" class="w-5 h-5" />
            <Icon v-else icon="heroicons:table-cells" class="w-5 h-5" />
          </template>
        </SelectButton>

        <!-- Sort Dropdown -->
        <Select
          v-model="sortKey"
          :options="sortOptions"
          optionLabel="label"
          placeholder="Sort Applications"
          @update:modelValue="onSortChange"
        />
      </div>

      <div class="dark:bg-charcoal bg-charcoal-100 p-10">
        <DataView
          :value="applications"
          :layout="layout"
          :rows="6"
          :sortField="sortField"
          :sortOrder="sortOrder"
        >
          <!-- List View -->
          <template #list="slotProps">
            <div>
              <div v-for="(item, index) in slotProps.items" :key="index" class="p-6 bg-white dark:bg-charcoal-900 shadow-sm rounded-lg border border-charcoal-200 dark:border-charcoal-800">
                <Box>
                  <Stack class="mr-14">
                    <Text size="large" weight="semibold" color="dark">{{ item.name }}</Text>
                    <Box gapX="xsmall" alignItems="center" class="mt-1">
                      <Text size="small" weight="medium" color="purple">{{ item.subheader }}</Text>
                      <span v-if="item.status === 'ACCEPTED'" class="text-mantis-100 bg-mantis-800/50 text-xs font-semibold px-3 py-0.5 rounded-full">
                        {{ item.status }}
                      </span>
                      <span v-if="item.status === 'EVALUATING'" class="text-white bg-blue-200/60 text-xs font-semibold px-3 py-0.5 rounded-full">
                        {{ item.status }}
                      </span>
                    </Box>
                    <Text size="small" color="light" class="block mt-3">{{ item.description }}</Text>
                  </Stack>
                  <Stack gap="xsmall" class="ml-4 flex-1 min-w-[12rem] mt-2">
                    <Box gapX="small" alignItems="center">
                      <Icon icon="heroicons:building-office" class="w-4 h-4 text-charcoal-700 dark:text-charcoal-200" />
                      <Text size="small" color="dark" weight="medium" class="min-w-[8rem]">Accounts Attached</Text>
                      <Text size="small" color="dark" weight="bold">{{ item.accounts }}</Text>
                    </Box>
                    <Box gapX="small" alignItems="center">
                      <Icon icon="heroicons:currency-dollar" class="w-4 h-4 text-charcoal-700 dark:text-charcoal-200" />
                      <Text size="small" color="dark" weight="medium" class="min-w-[8rem]">Potential ACV</Text>
                      <Text size="small" color="dark" weight="bold">{{ item.acv }}</Text>
                    </Box>
                    <Box gapX="small" alignItems="center">
                      <Icon icon="heroicons:calendar" class="w-4 h-4 text-charcoal-700 dark:text-charcoal-200" />
                      <Text size="small" color="dark" weight="medium" class="min-w-[8rem]">Voting Closes</Text>
                      <Text size="small" color="dark" weight="bold">{{ item.voteClose }}</Text>
                    </Box>
                  </Stack>

                  <Stack class="self-center">
                    <Button color="purple" size="sm">
                      Add Vote <Icon icon="heroicons:plus" class="w-5 h-5 ml-2" />
                    </Button>
                  </Stack>

                </Box>
              </div>
            </div>
          </template>

          <!-- Grid View -->
          <template #grid="slotProps">
            <div class="grid grid-cols-1 sm:grid-cols-3 2xl:grid-cols-5">
              <div v-for="(item, index) in slotProps.items" :key="index" class="relative p-8 pb-12 m-4 bg-white dark:bg-charcoal-900 shadow-sm rounded-lg border border-charcoal-200 dark:border-charcoal-800">

                <button class="bg-primary-link cursor-pointer top-2 right-2 absolute hover:bg-iris-500 text-white w-8 h-8 m-1 rounded-full flex items-center justify-center shadow">
                  <Icon icon="heroicons:plus" class="w-4 h-4" />
                </button>
                
                <Text size="large" weight="semibold" color="dark">{{ item.name }}</Text>
                <Box gapX="xsmall" alignItems="center" marginBottom="xsmall">
                  <Text size="small" weight="medium" color="purple">{{ item.subheader }}</Text>
                  <span v-if="item.status === 'ACCEPTED'" class="text-mantis-100 bg-mantis-800/50 text-xs font-semibold px-3 py-0.5 rounded-full">
                    {{ item.status }}
                  </span>
                  <span v-if="item.status === 'EVALUATING'" class="text-white bg-blue-200/60 text-xs font-semibold px-3 py-0.5 rounded-full">
                    {{ item.status }}
                  </span>
                </Box>
                <Text size="small" color="light">{{ item.description }}</Text>

                <Box marginTop="xsmall" gapY="xsmall" paddingTop="xsmall">
                  <Box gapX="small" alignItems="center">
                    <Icon icon="heroicons:building-office" class="w-4 h-4 text-charcoal-700 dark:text-charcoal-200" />
                    <Text size="small" color="dark" weight="medium" class="min-w-[8rem]">Accounts Attached</Text>
                    <Text size="small" color="dark" weight="bold">{{ item.accounts }}</Text>
                  </Box>
                  <Box gapX="small" alignItems="center">
                    <Icon icon="heroicons:currency-dollar" class="w-4 h-4 text-charcoal-700 dark:text-charcoal-200" />
                    <Text size="small" color="dark" weight="medium" class="min-w-[8rem]">Potential ACV</Text>
                    <Text size="small" color="dark" weight="bold">{{ item.acv }}</Text>
                  </Box>
                  <Box gapX="small" alignItems="center">
                    <Icon icon="heroicons:calendar" class="w-4 h-4 text-charcoal-700 dark:text-charcoal-200" />
                    <Text size="small" color="dark" weight="medium" class="min-w-[8rem]">Voting Closes</Text>
                    <Text size="small" color="dark" weight="bold">{{ item.voteClose }}</Text>
                  </Box>
                </Box>
              </div>
            </div>
          </template>
        </DataView>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import Text from '@/components/Core/Text.vue'
import Button from '@/components/Core/Button.vue'
import { Box, Stack } from '@/components/Core/Layout'
import DataView from 'primevue/dataview'
import SelectButton from 'primevue/selectbutton'
import Select from 'primevue/select'
import { Icon } from '@iconify/vue'

const layout = ref('list')
const layoutOptions = ['list', 'grid']

const sortKey = ref(null)
const sortField = ref('name')
const sortOrder = ref()

const sortOptions = [
  { label: 'Name A to Z', value: 'name' },
  { label: 'Name Z to A', value: '!name' }
]

const onSortChange = (event) => {
  const value = event.value
  sortKey.value = value
  if (value.startsWith('!')) {
    sortOrder.value = -1
    sortField.value = value.slice(1)
  } else {
    sortOrder.value = 1
    sortField.value = value
  }
}

const applications = ref([
  {
    name: 'Application Name #3',
    subheader: 'New AHV Validation',
    description: 'Brief description of Application based on requests received from SE Support Cases.',
    accounts: '10',
    acv: '$1,456,000',
    voteClose: '2025-4-12'
  },
  {
    name: 'Application Name #1',
    subheader: 'AHV Revalidation',
    description: 'Brief description of Application based on requests received from SE Support Cases.',
    accounts: '3',
    acv: '$80,000',
    voteClose: '2025-4-12',
    status: 'ACCEPTED'
  },
  {
    name: 'Application Name #2',
    subheader: 'New AHV Validation',
    description: 'Brief description of Application based on requests received from SE Support Cases.',
    accounts: '8',
    acv: '$1,000,000',
    voteClose: '2025-4-12'
  },
  {
    name: 'Application Name #5',
    subheader: 'AHV Revalidation',
    description: 'Brief description of Application based on requests received from SE Support Cases.',
    accounts: '1',
    acv: '$11,000',
    voteClose: '2025-4-12'
  },
  {
    name: 'Application Name #4',
    subheader: 'New AHV Validation',
    description: 'Brief description of Application based on requests received from SE Support Cases.',
    accounts: '12',
    acv: '$80,700',
    voteClose: '2025-4-12',
    status: 'EVALUATING'
  },
  {
    name: 'Application Name #6',
    subheader: 'AHV Revalidation',
    description: 'Brief description of Application based on requests received from SE Support Cases.',
    accounts: '2',
    acv: '$7,000',
    voteClose: '2025-4-12'
  }
])
</script>
