<template>
  <span
    @click="toggle"
    role="checkbox"
    tabindex="0"
    :aria-checked="modelValue"
    class="relative inline-flex shrink-0 border-2 border-transparent rounded-full transition-colors ease-in-out duration-200 focus:outline-hidden"
    :class="[ 
      modelValue ? 'bg-iris-700 dark:bg-iris-500' : 'bg-charcoal-200 dark:bg-charcoal-600',
      disabled ? 'opacity-70 cursor-not-allowed' : 'cursor-pointer',
      size == 'small' && 'h-4 w-9',
      size == 'medium' && 'h-5 w-10',
      size == 'large' && 'h-6 w-11',
    ]">
    <span
      aria-hidden="true"
      class="inline-block rounded-full bg-white dark:bg-charcoal-900 shadow-sm transform transition ease-in-out duration-200"
      :class="[
        reverse 
          ? (modelValue ? 'translate-x-0' : 'translate-x-5')
          : (modelValue ? 'translate-x-5' : 'translate-x-0'),
        size == 'small' && 'size-3',
        size == 'medium' && 'size-4',
        size == 'large' && 'size-5',
      ]"></span>
  </span>
</template>

<script setup>
const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false
  },
  size: {
    type: String,
    default: 'large',
    validator: (val) => ['small', 'medium', 'large'].includes(val)
  },
  reverse: {
    type: Boolean,
    default: false
  },
  disabled: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(['update:modelValue'])

function toggle() {
  if (props.disabled) {
    return
  }
  else {
    emit('update:modelValue', !props.modelValue)
  }
}
</script>
