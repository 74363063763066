<template>
  <div>
    <!-- Expanded Nav -->
    <nav class="">
      <div class="space-y-2 mt-5">
        <router-link :to="{ name: defaultAccountsRoute }" :class="expandMenu ? expandedStyles: defaultStyles" active-class="bg-iris-500 text-white">
          <Icon icon="heroicons:building-office" class="shrink-0 h-6 w-6 dark:group-hover:text-white" content="My Accounts"  v-tippy="{ onShow: showTippy, placement: 'right' }"/>
          <span v-show="expandMenu" class="truncate ml-3">
            My Accounts
          </span>
        </router-link>
        <router-link :to="{ name: `opportunities.${ defaultOpportunityRoute }` }" :class="expandMenu ? expandedStyles: defaultStyles"  active-class="bg-iris-500 text-white">
          <Icon icon="heroicons:sparkles" class="shrink-0 h-6 w-6  dark:group-hover:text-white" content="My Opportunities"  v-tippy="{ onShow: showTippy, placement: 'right' }"/>
          <span v-show="expandMenu" class="truncate ml-3">
            My Opportunities
          </span>
        </router-link>
        <router-link :to="{ name: 'myPOCs' }" :class="expandMenu ? expandedStyles: defaultStyles"  active-class="bg-iris-500 text-white">
          <Icon icon="heroicons:cpu-chip" class="shrink-0 h-6 w-6  dark:group-hover:text-white" content="My POCs"  v-tippy="{ onShow: showTippy, placement: 'right' }"/>
          <span v-show="expandMenu" class="truncate ml-3">
            My POCs
          </span>
        </router-link>
        <router-link v-if="showProductAdoption" :to="{ name: `myProductAdoptions.${ defaultProdAdoptionRoute }` }" :class="expandMenu ? expandedStyles: defaultStyles"  active-class="bg-iris-500 text-white">
          <Icon icon="heroicons:chart-pie" class="shrink-0 h-6 w-6  dark:group-hover:text-white" content="My Product Adoptions"  v-tippy="{ onShow: showTippy, placement: 'right' }"/>
          <span v-show="expandMenu" class="truncate ml-3">
            My Product Adoptions
          </span>
        </router-link>
        <router-link :to="{ name: 'my.tasks' }" :class="expandMenu ? expandedStyles: defaultStyles"  active-class="bg-iris-500">
          <Icon icon="heroicons:clipboard-document-list" class="shrink-0 h-6 w-6  dark:group-hover:text-white" content="My Tasks"  v-tippy="{ onShow: showTippy, placement: 'right' }"/>
          <span v-show="expandMenu" class="truncate ml-3">
            My Tasks
          </span>
        </router-link>
        <router-link v-if="user && user.hasReports" :to="{ name: 'team.list' }" :class="expandMenu ? expandedStyles: defaultStyles"  active-class="bg-iris-500">
          <Icon icon="heroicons:users" class="shrink-0 h-6 w-6  dark:group-hover:text-white" content="My Team"  v-tippy="{ onShow: showTippy, placement: 'right' }"/>
          <span v-show="expandMenu" class="truncate ml-3">
            My Team
          </span>
        </router-link>
        <router-link :to="{ name: 'contacts' }" :class="expandMenu ? expandedStyles: defaultStyles"  active-class="bg-iris-500">
          <Icon icon="heroicons:user-group" class="shrink-0 h-6 w-6  dark:group-hover:text-white" content="My Contacts"  v-tippy="{ onShow: showTippy, placement: 'right' }"/>
          <span v-show="expandMenu" class="truncate ml-3">
            My Contacts
          </span>
        </router-link>
      </div>
      <hr class="mt-5 border-iris-500">
      <div class="space-y-2 mt-5">
        <router-link :to="{ name: 'reports' }" :class="expandMenu ? expandedStyles: defaultStyles"  active-class="bg-iris-500 text-white">
          <Icon icon="heroicons:chart-bar" class="shrink-0 h-6 w-6  dark:group-hover:text-white" content="Reports"  v-tippy="{ onShow: showTippy, placement: 'right' }"/>
          <span v-show="expandMenu" class="truncate ml-3">
            Reports
          </span>
        </router-link>
        <router-link :to="{ name: 'resources' }" :class="expandMenu ? expandedStyles: defaultStyles"  active-class="bg-iris-500 text-white">
          <Icon icon="heroicons:light-bulb" class="shrink-0 h-6 w-6  dark:group-hover:text-white" content="Resources"  v-tippy="{ onShow: showTippy, placement: 'right' }"/>
          <span v-show="expandMenu" class="truncate ml-3">
            Resources
          </span>
        </router-link>
        <router-link :to="{ name: 'playground' }" :class="expandMenu ? expandedStyles: defaultStyles"  active-class="bg-iris-500 text-white">
          <Icon icon="heroicons:hand-thumb-up" class="shrink-0 h-6 w-6  dark:group-hover:text-white" content="Playground"  v-tippy="{ onShow: showTippy, placement: 'right' }"/>
          <span v-show="expandMenu" class="truncate ml-3">
            Field Alignment Requests
          </span>
        </router-link>
        <router-link v-if="audit_Active" :to="{ name: 'audit.my-pocs' }" :class="expandMenu ? expandedStyles: defaultStyles"  active-class="bg-iris-500">
          <Icon icon="heroicons:clipboard-document-check" class="shrink-0 h-6 w-6  dark:group-hover:text-white" content="POC Audit"  v-tippy="{ onShow: showTippy, placement: 'right' }"/>
          <div v-show="expandMenu"  class="flex-1 flex items-center justify-between truncate ml-3" >
            <span>
              POC Audit
            </span>
            <Badge v-if="permissions.pocCount > 0" tone="caution-nav">{{ permissions.pocCount }}</Badge>
          </div>
        </router-link>
        <router-link v-show="permissions.auditInitiator" :to="{ name: 'audit.options' }" :class="expandMenu ? expandedStyles: defaultStyles"  active-class="bg-iris-500">
          <Icon icon="heroicons:cog" class="shrink-0 h-6 w-6  dark:group-hover:text-white" content="Audit Options"  v-tippy="{ onShow: showTippy, placement: 'right' }"/>
          <span v-show="expandMenu" class="truncate ml-3">
            Audit Options
          </span>
        </router-link>
      </div>
      <hr class="mb-5 mt-5 border-iris-500">
      <div class="space-y-2 mt-5" v-show="!expandMenu">
        <router-link :to="{ name: 'recents' }" :class="expandMenu ? expandedStyles: defaultStyles"  active-class="bg-iris-500">
          <Icon icon="heroicons:clock" class="shrink-0 h-6 w-6  dark:group-hover:text-white" content="Recents"  v-tippy="{ onShow: showTippy, placement: 'right' }"/>
        </router-link>
      </div>

      <div v-if="recents.length > 0 && expandMenu">
        <div class="mt-4 mb-3 px-3 flex items-center justify-between">
          <h3 class="uppercase font-semibold text-xs text-iris-300 tracking-wider">Recents</h3>
          <div content="Show All" v-tippy>
            <router-link :to="{ name: 'recents' }">
              <div class="group first-letter:py-1 px-2 rounded-sm hover:bg-iris-500 active:bg-iris-700">
                <Icon icon="heroicons:ellipsis-horizontal" class="w-5 h-5 text-iris-300 group-hover:text-iris-700" />
              </div>
            </router-link>
          </div>
        </div>
        <div class="space-y-2">
          <div v-for="(recent, i) in recents.slice(0,5)" :key="i" class="group overflow-hidden hover:bg-charcoal-100">
            <div v-if="recent.type == 'Product_Adoption__c'">
              <router-link
                :to="{ name: 'productAdoption.show', props: true, params: { id: recent.id, prodAdoption: recent }}"
                :class="expandedStyles"
                active-class="bg-iris-500"
                >
                  <Icon icon="heroicons:chart-pie" class="shrink-0 h-6 w-6  dark:group-hover:text-white" />
                  <span class="truncate ml-3">
                    {{ recent.accountName }} - {{ recent.productName }}
                  </span>
              </router-link>
            </div>
            <div v-else>
              <router-link
                :to="{ name: recent.type === 'Account' ? 'account.opportunities' : 'opportunity.details', params: { id: recent.id }}"
                :class="expandedStyles"
                active-class="bg-iris-500"
              >
                <Icon :icon="recent.type == 'Account' ? 'heroicons:building-office': 'heroicons:sparkles'" class="shrink-0 h-6 w-6  dark:group-hover:text-white" />
                <span class="truncate ml-3">
                  {{ recent.name }}
                </span>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import Badge from '@/components/Core/Badge.vue'
import { Icon } from '@iconify/vue'
import { mapState } from 'vuex'
import { v2 } from '@/services/api.js'

export default {
  name: 'SecondaryNavigation',
  components: { Badge, Icon },
  props: ['user', 'expandMenu'],

  data() {
    return {
      recents: [],
      navIcon: 'menu',
      defaultStyles: 'flex px-3 py-2 hover:bg-iris-700',
      expandedStyles: 'group flex items-center px-3 py-2 text-sm font-medium hover:text-white hover:bg-iris-700 leading-normal focus:outline-hidden',
      defaultProdAdoptionRoute: 'list',
      defaultOpportunityRoute: 'list',
      defaultAccountsRoute: 'accounts'
    }
  },

  computed: {
    ...mapState('audit', ['permissions', 'audit_Active']),
    ...mapState(['preferredOpportunityView']),

    showProductAdoption() {
      return import.meta.env.VITE_PRODUCT_ADOPTION === 'true'
    }
  },

  methods: {
    showTippy() {
      if (this.expandMenu) {
        return false
      }

    }
  },

  async mounted() {
    this.recents = (await v2.get('/recents')).data

    let filterConfigTempProdAdoption = (await v2.get('/me/filters', { params: { filterType: 'productAdoption' }})).data
    let filterConfigTempOpportunity = (await v2.get('/me/filters', { params: { filterType: 'opportunity' }})).data
    let filterConfigTempAccounts = (await v2.get('/me/filters', { params: { filterType: 'accounts' }})).data
    // Checks if the user has saved filters already, otherwise load the default filters
    if (filterConfigTempProdAdoption.value.savedViews.length) {
      let temp = filterConfigTempProdAdoption.value.savedViews.findIndex(view => view.defaultView === true )
      if (filterConfigTempProdAdoption.value.savedViews[temp].viewLayout == 'list') {
        this.defaultProdAdoptionRoute = 'list'
      }
      else {
        this.defaultProdAdoptionRoute = 'board'
      }
    }
    if (filterConfigTempOpportunity.value.savedViews.length) {
      let temp = filterConfigTempOpportunity.value.savedViews.findIndex(view => view.defaultView === true )
      if (filterConfigTempOpportunity.value.savedViews[temp].viewLayout == 'list') {
        this.defaultOpportunityRoute = 'list'
      }
      else {
        this.defaultOpportunityRoute = 'board'
      }
    }
    if (filterConfigTempAccounts.value.savedViews.length) {
      let temp = filterConfigTempAccounts.value.savedViews.findIndex(view => view.defaultView === true )
      if (filterConfigTempAccounts.value.savedViews[temp].viewLayout == 'accounts') {
        this.defaultAccountsRoute = 'accounts'
      }
      else {
        this.defaultAccountsRoute = `heatmap.${filterConfigTempAccounts.value.savedViews[temp].viewLayout}`
      }
    }
  }
}
</script>
