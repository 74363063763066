<template>
  <DataTable
  :key="componentKey"
  :value="modelValue"
  :showGridlines="showGridlines"
  :size="size"
  :loading="tableLoading"
  :reorderableColumns="columnsReorder"
  column-resize-mode="expand"
  stateStorage="session"
  :stateKey="tableKey"
  @cell-edit-complete="cellEditComplete"
  @cell-edit-init="cellEditInital"
  :paginator="showPaginator"
  :paginatorPosition="paginatorPosition"
  :paginatorTemplate="paginatorTemplate"
  :rows="rows"
  :rowsPerPageOptions="rowsPerPageOptions"
  :pt="{
    root: ({ props }) => ({
      class: [
        'relative',
        'overflow-auto',
        // Flex & Alignment
        { 'flex flex-col': props.scrollable && props.scrollHeight === 'flex' },
        // Size
        { 'h-full': props.scrollable && props.scrollHeight === 'flex' }
      ]
    }),
    // Passing through DataView to Paginator Component
    pcPaginator: {
      root: {
        class: [
          'bg-transparent',

            // Color
            'text-charcoal-800 dark:text-charcoal-200',

            `justify-${justifyContent}`
        ]
      },

      current: {
        class: [
            // Color
            'text-charcoal-800 dark:text-charcoal-200',
        ]
      },

      page: ({ context }) => ({
        class: [
          { 'rounded-full border border-iris-600 dark:border-iris-400': context.active },
          'hover:bg-iris-600 hover:text-white bg-transparent'
        ]
      }),

      first: {
        class: [
          'hover:bg-iris-600 hover:text-white bg-transparent'
        ]
      },

      prev: {
        class: [
          'hover:bg-iris-600 hover:text-white bg-transparent'
        ]
      },
      next: {
        class: [
          'hover:bg-iris-600 hover:text-white bg-transparent'
        ]
      },

      last: {
        class: [
          'hover:bg-iris-600 hover:text-white bg-transparent'
        ]
      },

      // Styling for the Jump To Page Dropdown on Paginator Component
      pcJumpToPageDropdown: {
        root: {
          class: [
            // Color and Background
            'border',
            'border-charcoal-300 dark:border-charcoal-700',
            'bg-white dark:bg-charcoal-900'
          ]
        },
        // Passing through Paginator Component into Dropdown Component
        option: ({ context }) => ({
          class: [
          'hover:bg-iris-600 hover:text-white',
          { 'bg-iris-600 text-white': context.selected },
          { 'bg-transparent': context.focused && !context.selected },

          // Color
          'text-charcoal-800 dark:text-charcoal-200',
          ]
        }),

        label: {
          class: [
            // Color
            'text-charcoal-800 dark:text-charcoal-200',
          ]
        },

        overlay: {
          class: [
            // Color and Background
            'bg-white dark:bg-charcoal-900',
            'border border-charcoal-300 dark:border-charcoal-700'
          ]
        },
      },
      pcRowPerPageDropdown: {
        root: {
          class: [
            // Color and Background
            'border',
            'border-charcoal-300 dark:border-charcoal-700',
            'bg-white dark:bg-charcoal-900'
          ]
        },
        // Passing through Paginator Component into Dropdown Component
        option: ({ context }) => ({
          class: [
          'hover:bg-iris-600 hover:text-white',
          { 'bg-iris-600 text-white': context.selected },
          { 'bg-transparent': context.focused && !context.selected },

          // Color
          'text-charcoal-800 dark:text-charcoal-200',
          ]
        }),

        label: {
          class: [
            // Color
            'text-charcoal-800 dark:text-charcoal-200',
          ]
        },

        overlay: {
          class: [
            // Color and Background
            'bg-white dark:bg-charcoal-900',
            'border border-charcoal-300 dark:border-charcoal-700'
          ]
        },
      },

      pcJumpToPageInput: {
        class: [
          // Color
          'text-charcoal-800 dark:text-charcoal-200',
        ]
      }
    },
    mask: {
      class: [
        // Flex & Alignment
        'flex items-center justify-center',
        'absolute',
        'top-0 left-0',
        // Size
        'w-full h-full',
        // Color and Background
        'bg-charcoal-100/40 dark:bg-charcoal-900/40',
        // Misc
        'z-20',
        'transition duration-200'
      ]
    },
    loadingIcon: {
      class: 'w-8 h-8 animate-spin'
    },
    tableContainer: ({ props }) => ({
      class: [
        { relative: props.scrollable, 'flex flex-col grow': props.scrollable && props.scrollHeight === 'flex' },
        // Size
        { 'h-full': props.scrollable && props.scrollHeight === 'flex' }
      ]
    }),
    header: ({ props }) => ({
      class: [
        'font-bold',
        // Shape
        props.showGridlines ? 'border-x border-t border-b-0' : 'border-y border-x-0',
        // Spacing
        'p-4',
        // Color
        'bg-white dark:bg-charcoal-900',
        'border-charcoal-300 dark:border-charcoal-700',
        'text-charcoal-800 dark:text-charcoal-200'
      ]
    }),
    table: {
      class: 'w-full border-spacing-0 border-separate'
    },
    thead: ({ context }) => ({
      class: [
        //{ 'bg-white dark:bg-charcoal-900 top-0 z-40 sticky': context.scrollable }
        { 'bg-white dark:bg-charcoal-900 top-0 sticky': context.scrollable }
      ]
    }),
    tbody: ({ instance, context }) => ({
      class: [
        //{ 'sticky z-20': instance.frozenRow && context.scrollable },
        { 'sticky': instance.frozenRow && context.scrollable },
        'bg-white dark:bg-charcoal-900'
      ]
    }),
    tfoot: ({ context }) => ({
      class: [
        { 'bg-white dark:bg-charcoal-900 bottom-0 z-0': context.scrollable }
      ]
    }),
    footer: {
      class: [
        'font-bold',
        'border-t-0 border-b border-x-0',
        'p-4',
        'bg-white dark:bg-charcoal-900',
        'border-charcoal-300 dark:border-charcoal-700',
        'text-charcoal-800 dark:text-charcoal-200'
      ]
    },
    column: {
      headercell: ({ context, props }) => ({
        class: [
          'leading-[normal]',
          // Position
          { 'sticky border-b bg-white dark:bg-charcoal': props.frozen || props.frozen === '' },
          { relative: context.resizable },
          // Shape
          { 'first:border-l border-y border-r': context == null ? undefined : context.showGridlines },
          'border-0 border-b border-solid',
          // Spacing
          (context == null ? undefined : context.size) === 'default' || (context == null ? undefined : context.size) === 'small' ? 'py-1.5 px-2' : (context == null ? undefined : context.size) === 'large' ? 'py-[0.9375rem] px-5' : 'py-3 px-4',
          // Color
          (props.sortable === '' || props.sortable) && context.sorted ? 'bg-iris-700 dark:bg-iris-600 text-white' : 'bg-white text-charcoal-800 dark:text-charcoal-200 dark:bg-charcoal',
          'border-charcoal-300 dark:border-charcoal-700 ',
          // States
          { 'hover:bg-charcoal-100 dark:hover:bg-charcoal-900/50': (props.sortable === '' || props.sortable) && !(context != null && context.sorted) },
          'focus-visible:outline-hidden focus-visible:outline-offset-0 focus-visible:ring-1 focus-visible:ring-inset focus-visible:ring-primary-500 dark:focus-visible:ring-primary-400',
          // Transition
          { 'transition duration-200': props.sortable === '' || props.sortable },
          // Misc
          { 'cursor-pointer': props.sortable === '' || props.sortable },
          'whitespace-nowrap',
          {
            // Resizable
            'overflow-hidden space-nowrap border-y bg-clip-padding': context.resizable
          }
        ]
      }),
      columnTitle: {
        class: [ columnTitleStyle ? columnTitleStyle  : 'font-medium text-sm font-attention' ]
      },
      columnHeaderContent: {
        class: [
          'flex items-center',
          // HEADER PROP (justify-start, justify-center, justify-end)
          `justify-${header}`
        ]
      },
      /**
       * MIGHT NOT NEED THIS IF WE ARE LEAVING THE BACKGROUND THE BG-CHARCOAL
       */
      sort: {
        class: 'text-white'
      },
      bodyCell: ({ props, context, state, parent }) => ({
        class: [
          // Font
          'leading-[normal]',
          //Position
          { 'sticky box-border border-b': parent.instance.frozenRow },
          { 'sticky box-border border-b': props.frozen || props.frozen === '' },
          // Shape
          'border-0 border-b border-solid',
          { 'first:border-l border-r border-b': context == null ? undefined : context.showGridlines },
          { 'bg-white dark:bg-charcoal-900': parent.instance.frozenRow || props.frozen || props.frozen === '' },
          // Spacing
          { 'p-0': (context == null ? undefined : context.size) === 'default' && !state.d_editing },
          { 'py-1.5 px-2': (context == null ? undefined : context.size) === 'small' && !state.d_editing },
          { 'py-[0.9375rem] px-5': (context == null ? undefined : context.size) === 'large' && !state.d_editing },
          { 'py-3 px-4': (context == null ? undefined : context.size) !== 'default' && (context == null ? undefined : context.size) !== 'large' && (context == null ? undefined : context.size) !== 'small' && !state.d_editing },
          { 'py-[0.6rem] px-2': state.d_editing },
          // Color
          'border-charcoal-300 dark:border-charcoal-700'
        ]
      }),
      footerCell: ({ context }) => ({
        class: [
          // Font
          'font-bold',
          // Shape
          'border-0 border-b border-solid',
          { 'border-x border-y': context == null ? undefined : context.showGridlines },
          // Spacing
          (context == null ? undefined : context.size) === 'small' ? 'p-2' : (context == null ? undefined : context.size) === 'large' ? 'p-5' : 'p-4',
          // Color
          'border-charcoal-300 dark:border-charcoal-700',
          'text-charcoal-800 dark:text-charcoal-200',
          'bg-white dark:bg-charcoal-900'
        ]
      }),
      sortIcon: ({ context }) => ({
        class: 'dark:text-white text-charcoal-800'
      }),
      pcSortBadge: {
        class: [
          // Flex and Alignment
          'flex items-center justify-center align-middle',
          // Shape
          'rounded-full',
          'w-[1.143rem] leading-[1.143rem]',
          'ml-2',
          // Color and Background
          'text-white'
        ]
      },
      pcColumnFilterButton: {
        class: 'inline-flex items-center ml-auto font-normal'
      },
      filterOverlay: {
        class: [
          // Flex and Alignment
          'absolute top-0 left-0',
          // Shape
          'border-0 dark:border',
          'rounded-md',
          'shadow-md',
          'min-w-[12.5rem]',
          // Color and Background
          'bg-white dark:bg-charcoal-900',
          'border-charcoal-300 dark:border-charcoal-700',
          'text-charcoal-800 dark:text-charcoal-200',
        ]
      },
      /**
       * ============================= DO NOT KNOW WHAT THIS IS IN V4 =============================
       */
      filtermatchmodedropdown: {
        root: ({ state }) => ({
            class: [
              // Display and Position
              'flex',
              'relative',
              // Spacing
              'mb-2 last:mb-0',
              // Shape
              'w-full',
              'rounded-md',
              // Color and Background
              'bg-white dark:bg-charcoal-900',
              'border border-charcoal-300 dark:border-charcoal-700',
              'text-charcoal-800 dark:text-charcoal-200',
              'placeholder:text-charcoal-700 dark:placeholder:text-charcoal-400',
              // Transitions
              'transition-all',
              'duration-200',
              // States
              'hover:border-primary',
              { 'outline-hidden outline-offset-0 ring-1 ring-primary-500 dark:ring-primary-400': state.focused },
              // Misc
              'cursor-pointer',
              'select-none'
            ]
          })
        },
        filterrowitems: {
          class: 'm-0 p-0 py-3 list-none'
        },
        filterrowitem: ({ context }) => ({
          class: [
            // Font
            'font-normal',
            'leading-none',
            // Position
            'relative',
            // Shape
            'border-0',
            'rounded-none',
            // Spacing
            'm-0',
            'py-3 px-5',
            // Color
            { 'text-charcoal-800 dark:text-charcoal-200': !(context != null && context.highlighted) },
            { 'bg-white dark:bg-charcoal-900 text-charcoal-800 dark:text-charcoal-200': !(context != null && context.highlighted) },
            { 'bg-iris-700 dark:bg-iris-600 text-white': context == null ? undefined : context.highlighted },
            //States
            { 'hover:bg-charcoal-100 dark:hover:bg-[rgba(255,255,255,0.03)]': !(context != null && context.highlighted) },
            { 'hover:text-charcoal-700 hover:bg-charcoal-100 dark:hover:text-white dark:hover:bg-[rgba(255,255,255,0.03)]': !(context != null && context.highlighted) },
            'focus-visible:outline-hidden focus-visible:outline-offset-0 focus-visible:ring-1 focus-visible:ring-inset focus-visible:ring-primary-500 dark:focus-visible:ring-primary-400',
            // Transitions
            'transition-shadow',
            'duration-200',
            // Misc
            'cursor-pointer',
            'overflow-hidden',
            'whitespace-nowrap'
          ]
        }),
        filterOperator: {
          class: ['rounded-t-md', 'text-charcoal-800 dark:text-charcoal-200', 'bg-white dark:bg-charcoal-900']
        },
        pcFilterOperatorDropdown: {
          root: ({ state }) => ({
            class: [
              // Display and Position
              'inline-flex',
              'relative',
              // Shape
              'w-full',
              'rounded-md',
              // Color and Background
              'bg-white dark:bg-charcoal-900',
              'border border-charcoal-300 dark:border-charcoal-700',
              // Transitions
              'transition-all',
              'duration-200',
              // States
              'hover:border-charcoal-400 dark:hover:border-charcoal-600',
              { 'outline-hidden outline-offset-0 ring-1 ring-primary-500 dark:ring-primary-400 z-10': state.focused },
              // Misc
              'cursor-pointer',
              'select-none'
            ]
          }),
          input: ({ props }) => ({
            class: [
              //Font
              'leading-[normal]',
              // Display
              'block',
              'flex-auto',
              // Color and Background
              'bg-transparent',
              'border-0',
              // ========== Might not need both the with props and no props below =========
              { 'text-charcoal-800 dark:text-charcoal-200': props.modelValue, 'text-charcoal-800 dark:text-charcoal-200': !props.modelValue },
              'placeholder:text-charcoal-700 dark:placeholder:text-charcoal-400',
              // Sizing and Spacing
              'w-[1%]',
              'py-2 px-3',
              //Shape
              'rounded-none',
              // Transitions
              'transition',
              'duration-200',
              // States
              'focus:outline-hidden focus:shadow-none',
              // Misc
              'relative',
              'cursor-pointer',
              'overflow-hidden text-ellipsis',
              'whitespace-nowrap',
              'appearance-none'
            ]
          }),
          trigger: {
            class: [
              // Flex and Alignment
              'flex items-center justify-center',
              'shrink-0',
              // Colors and Background
              'bg-transparent',
              'text-charcoal-800 dark:text-charcoal-200',
              // Shape
              'w-12',
              'rounded-tr-md',
              'rounded-br-md'
            ]
          },
          panel: {
            class: [
              // Color and Background
              'bg-white dark:bg-charcoal-900',
              'text-charcoal-800 dark:text-charcoal-200',
              'border border-charcoal-300 dark:border-charcoal-700',
              // Shape
              'rounded-md',
              'shadow-md'
            ]
          },
          item: ({ context }) => ({
            class: [
              // Font
              'font-normal',
              'leading-none',
              // Position
              'relative',
              // Shape
              'border-0',
              'rounded-none',
              // Spacing
              'm-0 px-3 py-2',
              'first:mt-0 mt-[2px]',
              // Color
              {
                'text-charcoal-800 dark:text-charcoal-200': !context.focused && !context.selected,
                'bg-charcoal-200 dark:bg-charcoal-600/60': context.focused && !context.selected,
                'text-charcoal-800 dark:text-charcoal-200': context.focused && !context.selected,
                'text-white': context.selected,
                'bg-iris-600': context.selected
              },
              //States
              { 'hover:bg-charcoal-100 dark:hover:bg-[rgba(255,255,255,0.03)]': !context.focused && !context.selected },
              { 'hover:bg-primary-highlight-hover': context.selected },
              { 'hover:text-charcoal-700 hover:bg-charcoal-100 dark:hover:text-white dark:hover:bg-[rgba(255,255,255,0.03)]': context.focused && !context.selected },
              // Transitions
              'transition-shadow',
              'duration-200',
              // Misc
              'cursor-pointer',
              'overflow-hidden',
              'whitespace-nowrap'
            ]
          })
        },
        pcFilterAddRuleButton: {
          root: {
            class: [
              // Position
              'relative',
              // Flex and Alignment
              'items-center inline-flex text-center align-bottom justify-center',
              // Color and Backgrounds
              'bg-transparent border-transparent',
              // Shape
              'rounded-md',
              // Misc
              'text-sm py-[0.4375rem] px-[0.875rem] w-full',
              'text-primary',
              'hover:bg-primary-300/20',
              'transition duration-200 ease-in-out',
              'cursor-pointer overflow-hidden select-none'
            ]
          },
          label: {
            class: 'flex-auto grow-0'
          },
          icon: {
            class: 'mr-2'
          }
        },
        pcFilterRemoveRuleButton: {
          root: {
            class: [
              // Position
              'relative',
              // Flex and Alignment
              'items-center inline-flex text-center align-bottom justify-center',
              // Color and Background
              'bg-transparent border-transparent',
              'text-red-500 dark:text-red-400',
              'hover:bg-red-300/20',
              // Shape
              'rounded-md',
              // Misc
              'text-sm py-[0.4375rem] px-[0.875rem] w-full mt-2',
              'transition duration-200 ease-in-out',
              'cursor-pointer overflow-hidden select-none'
            ]
          },
          label: {
            class: 'flex-auto grow-0'
          },
          icon: {
            class: 'mr-2'
          }
        },
        filterbuttonbar: {
          class: ['flex items-center justify-between', 'p-0']
        },
        filterclearbutton: {
          root: {
              class: [
                // Position
                'relative',
                // Flex and alignment
                'items-center inline-flex text-center align-bottom justify-center',
                // Color and Background
                'text-primary-500 border border-primary',
                'hover:bg-primary-300/20',
                // Shape
                'rounded-md',
                // Misc
                'text-sm py-[0.4375rem] px-[0.875rem]',
                'transition duration-200 ease-in-out',
                'cursor-pointer overflow-hidden select-none'
              ]
          }
        },
        filterapplybutton: {
          root: {
            class: [
              // Position
              'relative',
              // Flex and Alignment
              'items-center inline-flex text-center align-bottom justify-center',
              // Color and Background
              'text-white',
              'bg-primary',
              'hover:bg-primary-hover hover:border-primary-hover',
              // Shape
              'rounded-md',
              // Misc
              'text-sm py-[0.4375rem] px-[0.875rem]',
              'transition duration-200 ease-in-out',
              'cursor-pointer overflow-hidden select-none'
            ]
          }
        },
        filterButtonbar: ({ context }) => ({
          class: [
            'relative',
            // Flex & Alignment
            'inline-flex items-center justify-center',
            // Size
            'w-8 h-8',
            // Spacing
            'ml-2',
            // Shape
            'rounded-full',
            // Color
            { 'bg-iris-600 text-white': context.active },
            'dark:text-white dark:hover:text-charcoal-100 dark:bg-charcoal-900',
            // States
            'hover:text-charcoal-700 hover:bg-charcoal-300/20',
            'focus:outline-hidden focus:outline-offset-0 focus:ring-1 focus:ring-primary-500 dark:focus:ring-primary-400',
            // Transition
            'transition duration-200',
            // Misc
            'cursor-pointer no-underline overflow-hidden'
          ]
        }),
        pcColumnFilterClearButton: ({ context }) => ({
          class: [
            'relative',
            // Flex & Alignment
            'inline-flex items-center justify-center',
            'text-left',
            // Shape
            'border-none',
            // Spacing
            'm-0 p-0 ml-2',
            // Color
            'bg-transparent',
            // Misc
            'cursor-pointer no-underline overflow-hidden select-none',
            {
              invisible: !context.hidden
            }
          ]
        }),
        rowToggleButton: {
          class: [
            'relative',
            // Flex and Alignment
            'inline-flex items-center justify-center',
            'text-left',
            // Color and Background
            'text-charcoal-800 dark:text-charcoal-200',
            'bg-transparent',
            // Spacing
            'm-0 p-0', 'w-8 h-8',
            // Shape
            'border-0 rounded-full',
            // Misc
            'focus-visible:outline-hidden focus-visible:outline-offset-0',
            'focus-visible:ring-1 focus-visible:ring-primary-500 dark:focus-visible:ring-primary-400',
            'transition duration-200',
            'overflow-hidden',
            'cursor-pointer select-none'
          ]
        },
        columnResizer: {
          class: [
            // Position
            'block',
            'absolute top-0 right-0',
            // Spacing
            'w-2 h-full',
            'm-0 p-0',
            'border border-transparent',
            // Misc
            'cursor-col-resize'
          ]
        },
        reorderablerowhandle: {
          class: 'cursor-move'
        },
        pcRowEditorInit: {
          class: [
            'relative',
            // Flex and Alignment
            'inline-flex items-center justify-center',
            'text-left',
            // Color and Background
            'text-charcoal-800 dark:text-charcoal-200',
            // Shape
            'w-7 h-7',
            'border-0 rounded-full',
            'border-transparent',
            // Misc
            'focus:outline-hidden focus:outline-offset-0 focus:ring-1 focus:ring-primary-500 dark:focus:ring-primary-400',
            'hover:text-charcoal-700 hover:bg-charcoal-50 dark:hover:bg-charcoal-800/50',
            'transition duration-200',
            'overflow-hidden',
            'cursor-pointer select-none'
          ]
        },
        pcRowEditorSave: {
          class: [
            'relative',
            // Flex and Alignment
            'inline-flex items-center justify-center',
            'text-left',
            // Color and Background
            'text-charcoal-800 dark:text-charcoal-200',
            // Shape
            'w-8 h-8',
            'border-0 rounded-full',
            'border-transparent',
            // Misc
            'focus:outline-hidden focus:outline-offset-0 focus:ring-1 focus:ring-primary-500 dark:focus:ring-primary-400',
            'hover:text-charcoal-700 hover:bg-charcoal-300/20',
            'transition duration-200',
            'overflow-hidden',
            'cursor-pointer select-none'
          ]
        },
        pcRowEditorCancel: {
          class: [
            'relative',
            // Flex and Alignment
            'inline-flex items-center justify-center',
            'text-left',
            // Color and Background
            'text-charcoal-800 dark:text-charcoal-200',
            // Shape
            'w-8 h-8',
            'border-0 rounded-full',
            'border-transparent',
            // Misc
            'focus:outline-hidden focus:outline-offset-0 focus:ring-1 focus:ring-primary-500 dark:focus:ring-primary-400',
            'hover:text-charcoal-700 hover:bg-charcoal-300/20',
            'transition duration-200',
            'overflow-hidden',
            'cursor-pointer select-none'
          ]
        },
        pcRowRadiobutton: {
          root: {
            class: ['relative', 'inline-flex', 'align-bottom', 'w-5 h-5', 'cursor-pointer', 'select-none']
          },
          box: ({ props }) => ({
            class: [
              // Flexbox
              'flex justify-center items-center',
              // Size
              'w-5 h-5',
              // Shape
              'border',
              'rounded-full',
              // Transition
              'transition duration-200 ease-in-out',
              // Colors
              { 'bg-iris-600': !props.disabled },
              {
                'text-charcoal-800 dark:text-charcoal-200': props.value !== props.modelValue && props.value !== undefined,
                'border-charcoal-300 dark:border-charcoal-700': props.value !== props.modelValue && props.value !== undefined && !props.invalid,
                'border-primary': props.value == props.modelValue && props.value !== undefined && !props.disabled
              },
              {
                'peer-hover:border-charcoal-400 dark:peer-hover:border-charcoal-400': !props.disabled && !props.invalid && props.value !== props.modelValue,
                'peer-hover:border-primary-hover': !props.disabled && props.value == props.modelValue && props.value !== undefined,
                '[&>*:first-child]:peer-hover:bg-primary-600 dark:[&>*:first-child]:peer-hover:bg-primary-300': !props.disabled && props.value == props.modelValue && props.value !== undefined,
                'peer-focus-visible:ring-1 peer-focus-visible:ring-primary-500 dark:peer-focus-visible:ring-primary-400': !props.disabled,
                'bg-charcoal-200 [&>*:first-child]:bg-charcoal-600 dark:bg-charcoal-700 dark:[&>*:first-child]:bg-charcoal-400 border-charcoal-300 dark:border-charcoal-700 select-none pointer-events-none cursor-default': props.disabled
              }
            ]
          }),
          input: {
            class: ['peer', 'w-full ', 'h-full', 'absolute', 'top-0 left-0', 'z-10', 'p-0', 'm-0', 'opacity-0', 'rounded-md', 'outline-hidden', 'border-1 border-charcoal-300 dark:border-charcoal-700', 'appearance-none', 'cursor-pointer']
          },
          icon: ({ props }) => ({
            class: [
              'block',
              // Shape
              'rounded-full',
              // Size
              'w-3 h-3',
              // Conditions
              {
                'bg-white dark:bg-charcoal-900 backface-hidden invisible scale-[0.1]': !props.modelValue,
                'bg-primary transform visible translate-z-0 scale-[1.1]': props.modelValue
              },
              // Transition
              'transition duration-200'
            ]
          })
        },
        pcHeaderCheckbox: {
          root: {
            class: ['relative', 'inline-flex', 'align-bottom', 'w-5', 'h-5', 'cursor-pointer', 'select-none']
          },
          box: ({ props, context }) => ({
            class: [
              // Alignment
              'flex',
              'items-center',
              'justify-center',
              // Size
              'w-5',
              'h-5',
              // Shape
              'rounded-sm',
              'border',
              // Colors
              {
                'border-charcoal-300 dark:border-charcoal-700': !context.checked && !props.invalid,
                'bg-iris-600': !context.checked && !props.invalid && !props.disabled,
                'border-primary bg-primary': context.checked
              },
              // Invalid State
              'invalid:focus:ring-red-200',
              'invalid:hover:border-red-500',
              { 'border-red-500 dark:border-red-400': props.invalid },
              // States
              {
                'peer-hover:border-charcoal-400 dark:peer-hover:border-charcoal-600': !props.disabled && !context.checked && !props.invalid,
                'peer-hover:bg-primary-hover peer-hover:border-primary-hover': !props.disabled && context.checked,
                'peer-focus-visible:z-10 peer-focus-visible:outline-hidden peer-focus-visible:outline-offset-0 peer-focus-visible:ring-1 peer-focus-visible:ring-primary-500 dark:peer-focus-visible:ring-primary-400': !props.disabled,
                'bg-charcoal-200 dark:bg-charcoal-700 select-none pointer-events-none cursor-default': props.disabled
              },
              // Transitions
              'transition-colors',
              'duration-200'
            ]
          }),
          input: {
            class: ['peer', 'w-full ', 'h-full', 'absolute', 'top-0 left-0', 'z-10', 'p-0', 'm-0', 'opacity-0', 'rounded-sm', 'outline-hidden', 'border border-charcoal-300 dark:border-charcoal-700', 'appearance-none', 'cursor-pointer']
          },
          icon: {
            class: ['w-[0.875rem]', 'h-[0.875rem]', 'text-charcoal-800 dark:text-charcoal-200', 'transition-all', 'duration-200']
          }
        },
        pcRowCheckbox: {
          root: {
            class: ['relative', 'inline-flex', 'align-bottom', 'w-5', 'h-5', 'cursor-pointer', 'select-none']
          },
          box: ({ props, context }) => ({
            class: [
              // Alignment
              'flex',
              'items-center',
              'justify-center',
              // Size
              'w-5',
              'h-5',
              // Shape
              'rounded-sm',
              'border',
              // Colors
              {
                'border-charcoal-300 dark:border-charcoal-700': !context.checked && !props.invalid,
                'bg-iris-600': !context.checked && !props.invalid && !props.disabled,
                'border-primary bg-primary': context.checked
              },
              // Invalid State
              'invalid:focus:ring-red-200',
              'invalid:hover:border-red-500',
              { 'border-red-500 dark:border-red-400': props.invalid },
              // States
              {
                'peer-hover:border-charcoal-400 dark:peer-hover:border-charcoal-600': !props.disabled && !context.checked && !props.invalid,
                'peer-hover:bg-primary-hover peer-hover:border-primary-hover': !props.disabled && context.checked,
                'peer-focus-visible:z-10 peer-focus-visible:outline-hidden peer-focus-visible:outline-offset-0 peer-focus-visible:ring-1 peer-focus-visible:ring-primary-500 dark:peer-focus-visible:ring-primary-400': !props.disabled,
                'bg-charcoal-200 dark:bg-charcoal-700 select-none pointer-events-none cursor-default': props.disabled
              },
              // Transitions
              'transition-colors',
              'duration-200'
            ]
          }),
          input: {
            class: ['peer', 'w-full ', 'h-full', 'absolute', 'top-0 left-0', 'z-10', 'p-0', 'm-0', 'opacity-0', 'rounded-sm', 'outline-hidden', 'border border-charcoal-300 dark:border-charcoal-700', 'appearance-none', 'cursor-pointer']
          },
          icon: {
            class: ['w-[0.875rem]', 'h-[0.875rem]', 'text-charcoal-800 dark:text-charcoal-200', 'transition-all', 'duration-200']
          }
        },
        transition: {
          class: 'p-3 flex flex-col gap-2',
          enterFromClass: 'opacity-0 scale-y-[0.8]',
          enterActiveClass: 'transition-[transform,opacity] duration-[120ms] ease-[cubic-bezier(0,0,0.2,1)]',
          leaveActiveClass: 'transition-opacity duration-100 ease-linear',
          leaveToClass: 'opacity-0'
        }
    },
    bodyRow: ({ context, props }) => ({
      class: [
        // Color
        'text-charcoal-800 dark:text-charcoal-200',
        //{ 'bg-iris-700 dark:bg-iris-600 text-white': context.selected },
        { 'bg-iris-500': context.selected },
        { 'bg-transparent': !context.selected },
        //{ 'font-bold bg-white dark:bg-charcoal-900 z-20': props.frozenRow },
        { 'font-bold bg-white dark:bg-charcoal-900': props.frozenRow },
        { 'odd:bg-charcoal-100 odd:text-charcoal-600 dark:odd:bg-charcoal-900 even:bg-charcoal-50 even:text-charcoal-600 dark:even:bg-charcoal-800/50': context.stripedRows },
        // State
        { 'focus:outline-hidden focus:outline-offset-0 focus:ring-1 focus:ring-primary-500 ring-inset dark:focus:ring-primary-400': context.selectable },
        { 'hover:bg-charcoal-300/20 dark:hover:bg-charcoal-800/50 hover:text-charcoal-600': props.selectionMode && !context.selected },
        // Transition
        { 'transition duration-200': props.selectionMode && !context.selected || props.rowHover },
        // Misc
        { 'cursor-pointer': props.selectionMode }
      ]
    }),
    rowExpansion: {
      class: 'bg-white dark:bg-charcoal-900 text-charcoal-600 dark:text-white/80'
    },
    rowGroupHeader: {
      class: ['sticky z-20', 'bg-charcoal-0 text-charcoal-600 dark:text-white/70', 'dark:bg-charcoal-900']
    },
    rowgroupfooter: {
      class: ['sticky z-20', 'bg-charcoal-0 text-charcoal-600 dark:text-white/70', 'dark:bg-charcoal-900']
    },
    rowGroupFooter: {
      class: [
        'relative',
        // Flex and Alignment
        'inline-flex items-center justify-center',
        'text-left',
        // Color and Background
        'text-charcoal-800 dark:text-charcoal-200',
        'bg-transparent',
        // Shape
        'm-0 p-0',
        'w-8 h-8',
        'border-0 rounded-full',
        // Misc
        'focus-visible:outline-hidden focus-visible:outline-offset-0',
        'focus-visible:ring-1 focus-visible:ring-primary-500 dark:focus-visible:ring-primary-400',
        'transition duration-200',
        'overflow-hidden',
        'cursor-pointer select-none'
      ]
    },
    rowToggleIcon: {
      class: 'inline-block w-4 h-4'
    },
    //resizehelper: {
    //  class: 'absolute hidden w-[2px] z-20 bg-primary'
    //}
  }"
  >
    <template #loading>
      <Loader color="#aaa" :size="32" />
    </template>

    <template #expansion="slotProps">
      <slot name="expansion" v-bind="slotProps" />
    </template>
    <slot />
  </DataTable>
</template>

<script setup>
import DataTable from 'primevue/datatable'
import Loader from '@/components/Core/Loader.vue'
import { computed } from 'vue'


const props = defineProps({
  header: {
    type: String,
    validator: (val) => ['start', 'center', 'end'].includes(val),
    default: 'center'
  },
  tableLoading: {
    type: Boolean
  },
  modelValue: {
    type: Array,
    required: true
  },
  showGridlines: {
    type: Boolean
  },
  size: {
    type: String,
    validator: (val) => ['default', 'small', 'medium', 'large'].includes(val),
    /**
     * Default sizing:
     * on bodyCell it defaults to p-0
     * on headerCell, default is the same as size small py-1.5 px-2
    */
    default: 'default'
  },
  // Override default columnTitle styling
  columnTitleStyle: {
    type: String
  },
  columnsReorder: {
    type: Boolean
  },
  tableKey: {
    type: String
  },
  componentKey: {
    type: String
  },
  paginatorPosition: {
    type: String
  },
  paginatorTemplate: {
    type: String
  },
  justifyContent: {
    type: String
  },
  rows: {
    type: Number
  },
  rowsPerPageOptions: {
    type: Array
  }
})
const emit = defineEmits(['onCellEditComplete', 'onCellEditInit'])

const showPaginator = computed(() => {
  if (props.modelValue.length > props.rows) {
    return true
  }
  return false
})

function cellEditComplete(event) {
  emit('onCellEditComplete', event)
}

function cellEditInital(event) {
  emit('onCellEditInit', event)
}


</script>