<template>
  <Box class="dark:bg-charcoal-950 bg-charcoal-100">
    <Box full alignItems="center" marginTop="small" marginLeft="small">
      <Text size="xsmall">Admin View</Text>
      <Toggle size="small" class="ml-2" v-model="adminView" />
    </Box>

    <!-- AHV Validation Table -->
    <Box justifyContent="center" full class="dark:bg-charcoal-950 bg-charcoal-100 mx-6 mb-12">
      <Box marginBottom="medium" marginTop="large" full noWrap>
        <Stack class="w-90">
          <Text size="large" noWrap attention weight="medium">
            AHV Application Validation
          </Text>
        </Stack>
        <Box full v-if="adminView" justifyContent="between">
          <Box alignItems="center">
            <Button color="purple" size="xs" @click="addNewDraftApplication()">
              Add New Application
              <Icon icon="heroicons:plus" class="w-4 h-4 ml-2" />
            </Button>
          </Box>
          <Box alignItems="right">
            <Button color="transparent" outline size="xs">
              Export CSV
              <Icon icon="heroicons:arrow-down-tray" class="w-4 h-4 ml-2" />
            </Button>
          </Box>
        </Box>
      </Box>


      <Box class="dark:bg-charcoal-800 bg-white" full justifyContent="center">
        <Table
          tableKey="ahvValidationTable"
          :modelValue="applications"
          dataKey="name"
          v-model:expandedRows="expandedRows"
          @rowExpand="onRowExpand"
          @rowCollapse="onRowCollapse"
          :rows="10"
          paginatorPosition="bottom"
          :rowsPerPageOptions="[10, 20, applications.length]"
          size="medium"
          class="w-full justify-center"
          @rowClick="handleRowClick"
          removableSort
        >

        <TableColumn header="Item Name" field="name" sortable>
          <template #body="{ data }">
            <Text
              :content="data.name"
              v-tippy="{ onShow: showTippy }"
              weight="medium"
              class="text-[15px] truncate w-50 ml-2"
              :class="data.isNew ? 'opacity-70' : ''"
            >
              {{ data.name }}
            </Text>
          </template>
        </TableColumn>

          <TableColumn header="Type" field="type">
            <template #body="{ data }">
              <Stack :class="data.isNew ? 'justify-center items-center' : ''">
                <Text size="small" color="light" class="text-nowrap">{{ data.type }}</Text>
              </Stack>
            </template>
          </TableColumn>

          <!--Admins will be able to see status (?)-->
          <TableColumn v-if="adminView" header="Status" field="status">
            <template #body="{ data }">
              <Box justifyContent="center">
                <span
                  v-if="data.status === 'PENDING'"
                  class="text-amber-700 bg-amber-100 dark:text-amber-100 dark:bg-amber-400/30 text-xs font-semibold px-3 py-0.5 rounded-full"
                >
                  {{ data.status }}
                </span>
                <span
                  v-else-if="data.status === 'DRAFT'"
                  class="text-red-700 bg-red-100 dark:text-red-100 dark:bg-red-400/60 text-xs font-semibold px-3 py-0.5 rounded-full"
                >
                  {{ data.status }}
                </span>
                <Text v-else size="small" color="light">—</Text>
              </Box>
            </template>
          </TableColumn>

          <TableColumn header="Votes" field="accounts" sortable>
            <template #body="{ data }">
              <Box justifyContent="center">
                <Text>{{ data.accounts }}</Text>
              </Box>
            </template>
          </TableColumn>

          <TableColumn header="Potential ACV" field="acv" sortable>
            <template #body="{ data }">
              <Box justifyContent="center">
                <Text weight="medium">{{ data.acv }}</Text>
              </Box>
            </template>
          </TableColumn>

          <TableColumn header="Voting Closes" field="voteClose" sortable>
            <template #body="{ data }">
              <Box justifyContent="center">
                <Text
                  size="small"
                  class="flex items-center w-28"
                  :color="isVoteClosingSoon(data.voteClose) ? 'amber' : 'light'"
                >
                  <Icon icon="heroicons:calendar-days" class="size-4 mr-2" />
                  {{ data.voteClose }}
                </Text>
              </Box>
            </template>
          </TableColumn>

          <TableColumn v-if="!adminView" header="Actions">
            <template #body>
              <Stack justifyContent="center" alignItems="center">
                <Button color="transparent" outline size="xs">
                  Add Vote
                  <Icon icon="heroicons:plus" class="w-4 h-4 ml-2" />
                </Button>
              </Stack>
            </template>
          </TableColumn>

          <TableColumn v-if="adminView" header="Admin">
            <template #body>
              <Stack alignItems="center">
                <Button color="transparent" size="sm">
                  <Icon icon="heroicons:pencil" class="w-4 h-4" />
                </Button>
              </Stack>
            </template>
          </TableColumn>

          <TableColumn expander style="width: 3rem">
            <template #body="{ data }">
              <button
                @click="toggleRow(data)"
                class="cursor-pointer"
              >
                <Icon
                  icon="heroicons:chevron-left"
                  :class="[
                    'w-5 h-5 transition-transform duration-200',
                    expandedRows[data.name] ? '-rotate-90' : 'rotate-0'
                  ]"
                />
              </button>
            </template>
          </TableColumn>

          <!--Expansion content-->
          <template #expansion="{ data }">
            <Box marginY="medium" marginLeft="xsmall" justifyContent="center">
              <Box full alignItems="center">
                <Icon icon="heroicons:information-circle" class="size-4 mr-2 mb-[3px]"/>
                <Text size="small" weight="medium" class="whitespace-pre">Description: </Text>
                <Text size="small" color="light"> {{ data.description }}</Text>
              </Box>
              <Box full marginTop="xsmall" alignItems="center">
                <Icon icon="heroicons:calendar-days" class="size-4 mr-2 mb-[3px]"/>
                <Text size="small" weight="medium" class="whitespace-pre">Date Opened: </Text>
                <Text size="small" color="light">{{ data.dateOpened }}</Text>
              </Box>
            </Box>

            <Text size="medium" v-if="!adminView && data.votes.filter(v => v.voterName === 'Emily Johnson').length" noWrap attention weight="medium" class="mt-5 ml-1">
              My Votes
            </Text>

            <!--Table for showing just the user's votes. Emily as placeholder to mock it-->
            <Table
              v-if="!adminView && data.votes.filter(v => v.voterName === 'Emily Johnson').length"
              tableKey="voteTable"
              :modelValue="data.votes.filter(v => v.voterName === 'Emily Johnson')"
              size="medium"
              class="w-full justify-center mb-6 mt-3 border-[0.5px] border-charcoal-300 dark:border-charcoal-700"
              :rows="data.votes.filter(v => v.voterName === 'Emily Johnson').length"
              dataKey="placeholder"
              columnTitleStyle="text-xs font-attention"
            >

              <TableColumn header="Account" field="accountName">
                <template #body="{ data }">
                  <Text v-if="data.accountName" size="small" class="truncate cursor-pointer text-primary-link dark:text-iris-200 hover:text-iris-900 dark:hover:text-iris-100 hover:underline transition duration-150 ease-in-out">
                    {{ data.accountName }}
                  </Text>
                </template>
              </TableColumn>

              <TableColumn header="Opportunity" field="opportunityName">
                <template #body="{ data }">
                  <Text v-if="data.opportunityName" size="small" class="truncate cursor-pointer text-primary-link dark:text-iris-200 hover:text-iris-900 dark:hover:text-iris-100 hover:underline transition duration-150 ease-in-out">
                    {{ data.opportunityName }}
                  </Text>
                  <Box v-else justifyContent="center">
                    <Text size="small" color="light">—</Text>
                  </Box>
                </template>
              </TableColumn>

              <TableColumn header="Potential ACV" field="acv">
                <template #body="{ data }">
                  <Box justifyContent="center">
                    <Text size="small" >{{ data.acv }}</Text>
                  </Box>
                </template>
              </TableColumn>

              <TableColumn header="Vote Cast Date" field="acv">
                <template #body="{ data }">
                  <Box justifyContent="center" alignItems="center">
                    <Icon icon="heroicons:calendar-days" class="size-3 mr-2" />
                    <Text class="flex items-center" size="small" color="light">{{ data.date }}</Text>
                  </Box>
                </template>
              </TableColumn>

              <TableColumn header="Actions">
                <template #body>
                  <Stack alignItems="center">
                    <Button color="transparent" size="sm">
                      <Icon icon="heroicons:pencil" class="w-4 h-4" />
                    </Button>
                  </Stack>
                </template>
              </TableColumn>

            </Table>

            <!--Table for admins to see all votes-->
            <Table
              v-if="adminView && data.votes.length"
              tableKey="voteTable"
              :modelValue="data.votes"
              size="medium"
              class="w-full justify-center mb-6 mt-5 border-[0.5px] border-charcoal-300 dark:border-charcoal-700"
              :rows="data.votes.length"
              dataKey="placeholder2"
              columnTitleStyle="text-xs font-attention"
            >

              <TableColumn header="Account" field="accountName">
                <template #body="{ data }">
                  <Text v-if="data.accountName" size="small" class="truncate w-60 cursor-pointer text-primary-link dark:text-iris-200 hover:text-iris-900 dark:hover:text-iris-100 hover:underline transition duration-150 ease-in-out">
                    {{ data.accountName }}
                  </Text>
                </template>
              </TableColumn>

              <TableColumn header="Opportunity" field="opportunityName">
                <template #body="{ data }">
                  <Text v-if="data.opportunityName" size="small" class="truncate w-60 cursor-pointer text-primary-link dark:text-iris-200 hover:text-iris-900 dark:hover:text-iris-100 hover:underline transition duration-150 ease-in-out">
                    {{ data.opportunityName }}
                  </Text>
                  <Box v-else justifyContent="center">
                    <Text size="small" color="light">—</Text>
                  </Box>
                </template>
              </TableColumn>

              <TableColumn header="Potential ACV" field="acv">
                <template #body="{ data }">
                  <Box justifyContent="center">
                    <Text size="small" >{{ data.acv }}</Text>
                  </Box>
                </template>
              </TableColumn>

              <TableColumn header="Voter" field="voter">
                <template #body="{ data }">
                  <Stack alignItems="center">
                    <Text color="light" size="small" v-tippy content="Show info abt voter (for admin?)">{{ data.voterName }}</Text>
                  </Stack>
                </template>
              </TableColumn>

              <TableColumn header="Vote Cast Date" field="acv">
                <template #body="{ data }">
                  <Box justifyContent="center" alignItems="center">
                    <Icon icon="heroicons:calendar-days" class="size-3 mr-2" />
                    <Text class="flex items-center" size="small" color="light">{{ data.date }}</Text>
                  </Box>
                </template>
              </TableColumn>
            </Table>
          </template>
        </Table>
      </Box>
    </Box>

    <!-- History Section -->
    <Box justifyContent="center" full class="flex items-center justify-between px-8 mt-16 mb-4">
      <Box marginBottom="small" full alignItems="center" noWrap>
        <Stack noWrap class="w-120">
          <Text size="large" attention weight="medium">
            Field Alignment Request History
          </Text>
        </Stack>
        <Box full noWrap justifyContent="between">
          <Stack full>
            <Box alignItems="center">
              <Icon icon="heroicons:magnifying-glass" class="mr-2 text-charcoal-600 dark:text-charcoal-300 w-4 h-4" />
              <input
                v-model="searchTerm"
                type="text"
                placeholder="Search by application name"
                class="rounded block pl-2 border-0 appearance-none flex-1 text-sm leading-6 placeholder-charcoal-700 focus:ring-0 focus:placeholder-charcoal-400 sm:text-sm dark:placeholder-charcoal-400 dark:focus:placeholder-charcoal-700 dark:text-charcoal-200"
                :class="panelEmbed == true ? 'bg-charcoal-100 dark:bg-charcoal' : 'bg-white dark:bg-charcoal-900'"
              />
            </Box>
          </Stack>
          <Stack v-if="adminView" noWrap class="w-1/7 ml-2" alignItems="end" justifyContent="center">
            <Button color="transparent" outline size="xs">
              Export CSV
              <Icon icon="heroicons:arrow-down-tray" class="w-4 h-4 ml-2" />
            </Button>
          </Stack>
        </Box>
      </Box>

      <Box class="dark:bg-charcoal-800 bg-white" full justifyContent="center">
        <Table
          tableKey="historyValidationTable"
          :modelValue="filteredApplications"
          size="medium"
          class="w-full justify-center"
          :rows="10"
          paginator
          paginatorPosition="bottom"
          :rowsPerPageOptions="[10, 20, filteredApplications.length]"
          dataKey="name"
          v-model:expandedRows="expandedRows"
          @rowExpand="onRowExpand"
          @rowCollapse="onRowCollapse"
          removableSort
        >

          <TableColumn header="Item Name" field="name" sortable>
            <template #body="{ data }">
              <Text :content="data.name" v-tippy="{ onShow: showTippy}" weight="medium" class="text-[15px] truncate w-50 ml-2">{{ data.name }}</Text>
            </template>
          </TableColumn>

          <TableColumn header="Type" field="type">
            <template #body="{ data }">
              <Stack>
                <Text size="small" color="light" class="text-nowrap">{{ data.type }}</Text>
              </Stack>
            </template>
          </TableColumn>

          <TableColumn header="Status" field="status">
            <template #body="{ data }">
              <Box justifyContent="center">
                <span
                  v-if="data.status === 'ACCEPTED'"
                  class="text-green-700 bg-green-100 dark:text-mantis-100 dark:bg-mantis-800/50 text-xs font-semibold px-3 py-0.5 rounded-full"
                >
                  {{ data.status }}
                </span>
                <span
                  v-else-if="data.status === 'REJECTED'"
                  class="text-red-700 bg-red-100 dark:text-red-100 dark:bg-red-400/60 text-xs font-semibold px-3 py-0.5 rounded-full"
                >
                  {{ data.status }}
                </span>
                <Text v-else size="small" color="light">—</Text>
              </Box>
            </template>
          </TableColumn>

          <TableColumn header="Votes" field="accounts" sortable>
            <template #body="{ data }">
              <Box justifyContent="center">
                <Text>{{ data.accounts }}</Text>
              </Box>
            </template>
          </TableColumn>

          <TableColumn header="Potential ACV" field="acv" sortable>
            <template #body="{ data }">
              <Box justifyContent="center">
                <Text weight="medium">{{ data.acv }}</Text>
              </Box>
            </template>
          </TableColumn>

          <TableColumn header="Voting Closed" field="voteClose" sortable>
            <template #body="{ data }">
              <Box justifyContent="center">
                <Text size="small" class="flex items-center w-28" color="light">
                  <Icon icon="heroicons:calendar-days" class="size-4 mr-2" />
                  {{ data.voteClose }}
                </Text>
              </Box>
            </template>
          </TableColumn>

          <TableColumn v-if="adminView" header="Admin">
            <template #body>
              <Stack alignItems="center">
                <Button color="transparent" size="sm">
                  <Icon icon="heroicons:pencil" class="w-4 h-4" />
                </Button>
              </Stack>
            </template>
          </TableColumn>

          <TableColumn expander style="width: 3rem">
            <template #body="{ data }">
              <button
                @click="toggleRow(data)"
                class="cursor-pointer"
              >
                <Icon
                  icon="heroicons:chevron-left"
                  :class="[
                    'w-5 h-5 transition-transform duration-200',
                    expandedRows[data.name] ? '-rotate-90' : 'rotate-0'
                  ]"
                />
              </button>
            </template>
          </TableColumn>

          <!--Expansion content-->
          <template #expansion="{ data }">
            <Box marginY="medium" marginLeft="xsmall" justifyContent="center">
              <Box full alignItems="center">
                <Icon icon="heroicons:information-circle" class="size-4 mr-2"/>
                <Text size="small" weight="medium" class="whitespace-pre">Description: </Text>
                <Text size="small" color="light"> {{ data.description }}</Text>
              </Box>
              <Box full marginTop="xsmall" alignItems="center">
                <Icon icon="heroicons:calendar-days" class="size-4 mr-2"/>
                <Text size="small" weight="medium" class="whitespace-pre">Date Opened: </Text>
                <Text size="small" color="light">{{ data.dateOpened }}</Text>
              </Box>
            </Box>

            <Text size="medium" v-if="!adminView && data.votes.filter(v => v.voterName === 'Emily Johnson').length" noWrap attention weight="medium" class="mt-5 ml-1">
              My Votes
            </Text>

            <!--Table for showing just the user's votes. Used Emily as name to mock this.-->
            <Table
              v-if="!adminView && data.votes.filter(v => v.voterName === 'Emily Johnson').length"
              tableKey="voteTable"
              :modelValue="data.votes.filter(v => v.voterName === 'Emily Johnson')"
              size="medium"
              class="w-full justify-center mb-6 mt-3 border-[0.5px] border-charcoal-300 dark:border-charcoal-700"
              :rows="data.votes.filter(v => v.voterName === 'Emily Johnson').length"
              dataKey="placeholder3"
              columnTitleStyle="text-xs font-attention"
            >

              <TableColumn header="Account" field="accountName">
                <template #body="{ data }">
                  <Text v-if="data.accountName" size="small" class="truncate cursor-pointer text-primary-link dark:text-iris-200 hover:text-iris-900 dark:hover:text-iris-100 hover:underline transition duration-150 ease-in-out">
                    {{ data.accountName }}
                  </Text>
                </template>
              </TableColumn>

              <TableColumn header="Opportunity" field="opportunityName">
                <template #body="{ data }">
                  <Text v-if="data.opportunityName" size="small" class="truncate cursor-pointer text-primary-link dark:text-iris-200 hover:text-iris-900 dark:hover:text-iris-100 hover:underline transition duration-150 ease-in-out">
                    {{ data.opportunityName }}
                  </Text>
                  <Box v-else justifyContent="center">
                    <Text size="small" color="light">—</Text>
                  </Box>
                </template>
              </TableColumn>

              <TableColumn header="Potential ACV" field="acv">
                <template #body="{ data }">
                  <Box justifyContent="center">
                    <Text size="small" >{{ data.acv }}</Text>
                  </Box>
                </template>
              </TableColumn>

              <TableColumn header="Vote Cast Date" field="acv">
                <template #body="{ data }">
                  <Box justifyContent="center" alignItems="center">
                    <Icon icon="heroicons:calendar-days" class="size-3 mr-2" />
                    <Text class="flex items-center" size="small" color="light">{{ data.date }}</Text>
                  </Box>
                </template>
              </TableColumn>
            </Table>

            <!--Table for admins to see all votes-->
            <Table
              v-if="adminView && data.votes.length"
              tableKey="voteTable"
              :modelValue="data.votes"
              size="medium"
              class="w-full justify-center mb-6 mt-5 border-[0.5px] border-charcoal-300 dark:border-charcoal-700"
              :rows="data.votes.length"
              dataKey="placeholder4"
              columnTitleStyle="text-xs font-attention"
            >

              <TableColumn header="Account" field="accountName">
                <template #body="{ data }">
                  <Text v-if="data.accountName" size="small" class="truncate w-60 cursor-pointer text-primary-link dark:text-iris-200 hover:text-iris-900 dark:hover:text-iris-100 hover:underline transition duration-150 ease-in-out">
                    {{ data.accountName }}
                  </Text>
                </template>
              </TableColumn>

              <TableColumn header="Opportunity" field="opportunityName">
                <template #body="{ data }">
                  <Text v-if="data.opportunityName" size="small" class="truncate w-60 cursor-pointer text-primary-link dark:text-iris-200 hover:text-iris-900 dark:hover:text-iris-100 hover:underline transition duration-150 ease-in-out">
                    {{ data.opportunityName }}
                  </Text>
                  <Box v-else justifyContent="center">
                    <Text size="small" color="light">—</Text>
                  </Box>
                </template>
              </TableColumn>

              <TableColumn header="Potential ACV" field="acv">
                <template #body="{ data }">
                  <Box justifyContent="center">
                    <Text size="small">{{ data.acv }}</Text>
                  </Box>
                </template>
              </TableColumn>

              <TableColumn header="Voter" field="voter">
                <template #body="{ data }">
                  <Stack alignItems="center">
                    <Text color="light" size="small" v-tippy content="Show info abt voter (for admin?)">{{ data.voterName }}</Text>
                  </Stack>
                </template>
              </TableColumn>

              <TableColumn header="Vote Cast Date" field="acv">
                <template #body="{ data }">
                  <Box justifyContent="center" alignItems="center">
                    <Icon icon="heroicons:calendar-days" class="size-3 mr-2" />
                    <Text class="flex items-center" size="small" color="light">{{ data.date }}</Text>
                  </Box>
                </template>
              </TableColumn>
            </Table>
          </template>
        </Table>
      </Box>
    </Box>
  </Box>
</template>

<script setup>
import { ref, computed } from 'vue'
import { format } from 'date-fns'
import Text from '@/components/Core/Text.vue'
import { Box, Stack } from '@/components/Core/Layout'
import { Table, TableColumn } from '@/components/Core/Table'
import Toggle from '@/components/Core/Toggle.vue'
import Button from '@/components/Core/Button.vue'
import { Icon } from '@iconify/vue'
import { isBefore, addDays, parseISO } from 'date-fns'

const mockVotes = [
  {
    accountName: 'Doha Insurance Group',
    opportunityName: 'VDI - PriceBookv5',
    acv: '$70,000',
    date: '2024-04-01',
    voterName: 'Emily Johnson',
    voterEmail: 'emily.johnson@nutanix.com'
  },
  {
    accountName: 'Industries Qatar Sub-Ultimate Parent',
    opportunityName: null,
    acv: '$86,000',
    date: '2024-04-02',
    voterName: 'Evan Eischer',
    voterEmail: 'evan.eischer@nutanix.com'
  },
  {
    accountName: 'Mannai - QT',
    opportunityName: 'GIS_ Infra Technology Refresh GIS_ Infra Technology Refresh GIS_ Infra Technology Refresh GIS_ Infra Technology Refresh',
    acv: '$100,000',
    date: '2024-04-03',
    voterName: 'Ryan Frazier',
    voterEmail: 'ryan.frazier@nutanix.com'
  }
]

const mockVotes2 = [
  {
    accountName: 'Industries Qatar Sub-Ultimate Parent',
    opportunityName: null,
    acv: '$86,000',
    date: '2024-04-02',
    voterName: 'Evan Eischer',
    voterEmail: 'evan.eischer@nutanix.com'
  },
  {
    accountName: 'Mannai - QT',
    opportunityName: 'GIS_ Infra Technology Refresh GIS_ Infra Technology Refresh GIS_ Infra Technology Refresh GIS_ Infra Technology Refresh',
    acv: '$100,000',
    date: '2024-04-03',
    voterName: 'Ryan Frazier',
    voterEmail: 'ryan.frazier@nutanix.com'
  }
]

const searchTerm = ref('')
const expandedRows = ref({})
const adminView = ref(false)

// Until we have a drawer component with a delete function, this can work to get rid of new draft rows.
// Just click the newly added row and it will be deleted
function handleRowClick(event) {
  const row = event.data
  if (row.isNew) {
    applications.value = applications.value.filter(app => app !== row)
  }
}

function addNewDraftApplication() {
  applications.value.unshift({
    name: 'Untitled',
    type: '—',
    acv: '$0',
    accounts: '0',
    voteClose: format(new Date(), 'yyyy-MM-dd'),
    status: 'DRAFT',
    description: '',
    dateOpened: format(new Date(), 'yyyy-MM-dd'),
    votes: [],
    isNew: true 
  })
}

/**
 * Show tippy on account name if longer than xx characters
*/
function showTippy(e) {
  if(e.reference.innerText.length < 25){
    return false
  }
}

const isVoteClosingSoon = (voteCloseDate) => {
  if (!voteCloseDate) return false
  const today = new Date()
  const date = parseISO(voteCloseDate)
  return isBefore(date, addDays(today, 7))
}

const toggleRow = (data) => {
  const key = data.name
  const isExpanded = expandedRows.value[key]

  if (isExpanded) {
    delete expandedRows.value[key]
  } else {
    expandedRows.value = {
      ...expandedRows.value,
      [key]: true
    }
  }
}

const applications = ref([
  {
    name: 'GitLab Server',
    type: 'AHV Revalidation',
    acv: '$1,000,000',
    accounts: 8,
    voteClose: '2025-04-20',
    status: 'DRAFT',
    description: 'Request to verify compatibility with custom CI/CD pipelines deployed in enterprise environments.',
    votes: mockVotes,
    dateOpened: '2024-11-29'
  },
  {
    name: 'Slack Enterprise Grid',
    type: 'New AHV Validation',
    acv: '$80,000',
    accounts: 3,
    voteClose: '2025-03-30',
    status: 'PENDING',
    description: 'Assessment needed for integration with secure messaging channels and AHV network policies.',
    votes: mockVotes2,
    dateOpened: '2024-11-29'
  },
  {
    name: 'International Unified Cloud Synchronization and Visualization Suite',
    type: 'New AHV Validation',
    acv: '$1,456,000',
    accounts: 10,
    voteClose: '2025-05-01',
    status: 'ACCEPTED',
    description: 'Validation request for incident automation platform operating within AHV container clusters.',
    votes: mockVotes,
    dateOpened: '2024-11-29'
  },
  {
    name: 'Workday Connectors',
    type: 'AHV Revalidation',
    acv: '$80,700',
    accounts: 12,
    voteClose: '2025-04-15',
    status: 'REJECTED',
    description: 'SEs reported inconsistency syncing HR data via Workday connectors in AHV environments.',
    votes: mockVotes2,
    dateOpened: '2024-11-29'
  }
])

const applications2 = ref([
  {
    name: 'Figma Workspace',
    type: 'New AHV Validation',
    acv: '$312,000',
    accounts: 6,
    voteClose: '2024-12-14',
    status: 'ACCEPTED',
    description: 'Design team requests review of Figma performance on virtual desktop interfaces hosted on AHV.',
    votes: mockVotes,
    dateOpened: '2024-11-29'
  },
  {
    name: 'International Unified Cloud Synchronization and Visualization Suite',
    type: 'AHV Revalidation',
    acv: '$987,500',
    accounts: 9,
    voteClose: '2024-11-08',
    description: 'Investigation into audio and video stream handling under AHV virtual network architecture.',
    votes: mockVotes2,
    dateOpened: '2024-11-29'
  },
  {
    name: 'Atlassian Bitbucket',
    type: 'New AHV Validation',
    acv: '$246,300',
    accounts: 3,
    voteClose: '2024-10-26',
    status: 'ACCEPTED',
    description: 'Validation requested for Bitbucket Pipelines when used in AHV-integrated dev workflows.',
    votes: mockVotes,
    dateOpened: '2024-11-29'
  },
  {
    name: 'Oracle NetSuite ERP',
    type: 'AHV Revalidation',
    acv: '$1,150,000',
    accounts: 11,
    voteClose: '2024-09-12',
    description: 'Concerns raised about latency and database sync when deployed via AHV VMs.',
    votes: mockVotes2,
    dateOpened: '2024-11-29'
  },
  {
    name: 'Snowflake Data Cloud',
    type: 'New AHV Validation',
    acv: '$890,400',
    accounts: 7,
    voteClose: '2024-12-03',
    status: 'ACCEPTED',
    description: 'Field teams requested validation of compute-intensive queries on AHV-backed infrastructure.',
    votes: mockVotes,
    dateOpened: '2024-11-29'
  },
  {
    name: 'Salesforce Service Cloud',
    type: 'AHV Revalidation',
    acv: '$634,200',
    accounts: 5,
    voteClose: '2024-11-19',
    description: 'Service teams reported slow performance when integrating SFDC workflows with AHV VMs.',
    votes: mockVotes2,
    dateOpened: '2024-11-29'
  },
  {
    name: 'Databricks Workspace',
    type: 'New AHV Validation',
    acv: '$1,280,000',
    accounts: 10,
    voteClose: '2024-10-04',
    description: 'Need to validate Spark processing engine compatibility in AHV-hosted analytics clusters.',
    votes: mockVotes,
    dateOpened: '2024-11-29'
  },
  {
    name: 'Jira Align Platform',
    type: 'AHV Revalidation',
    acv: '$512,300',
    accounts: 6,
    voteClose: '2024-08-21',
    status: 'REJECTED',
    description: 'Reported issues with real-time sync between Jira Align and Jira Software on AHV.',
    votes: mockVotes2,
    dateOpened: '2024-11-29'
  },
  {
    name: 'SAP SuccessFactors',
    type: 'New AHV Validation',
    acv: '$1,022,900',
    accounts: 8,
    voteClose: '2024-07-15',
    status: 'ACCEPTED',
    description: 'Validation needed for SAP HR modules running on AHV-based enterprise resource platforms.',
    votes: mockVotes2,
    dateOpened: '2024-11-29'
  },
  {
    name: 'Cisco Webex Suite',
    type: 'AHV Revalidation',
    acv: '$378,100',
    accounts: 4,
    voteClose: '2024-10-30',
    description: 'End users reported call quality degradation under high AHV VM load conditions.',
    votes: mockVotes,
    dateOpened: '2024-11-29'
  },
  {
    name: 'Microsoft Power BI Gateway',
    type: 'New AHV Validation',
    acv: '$705,800',
    accounts: 7,
    voteClose: '2024-09-10',
    description: 'SE team requested validation of Power BI report generation on AHV infrastructure.',
    votes: mockVotes2,
    dateOpened: '2024-11-29'
  },
  {
    name: 'ServiceNow ITOM',
    type: 'AHV Revalidation',
    acv: '$945,600',
    accounts: 9,
    voteClose: '2024-11-02',
    status: 'REJECTED',
    description: 'Request to validate IT Operations Monitoring features and alert integration in AHV setups.',
    votes: mockVotes,
    dateOpened: '2024-11-29'
  }
])

const truncateText = (text, limit) => {
  if (!text) return ''
  return text.length > limit ? text.substring(0, limit).trim() + '…' : text
}

const filteredApplications = computed(() => {
  if (!searchTerm.value) return applications2.value
  return applications.value.filter(app =>
    app.name.toLowerCase().includes(searchTerm.value.toLowerCase())
  )
})

</script>
